.loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    flex-direction: column;
  }
  
.loading-container {
width: 80%;
max-width: 500px;
text-align: center;
}

.image-container {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
  }
  
  .loading-image {
    width: 240px; /* Adjust the size as needed */
    /* height: 80px;  */
    border-radius: 8px; /* Rounded edges */
    margin: 0 30px; /* Space between images */
  }

.loading-bar {
width: 100%;
height: 20px;
background-color: #333;
border-radius: 10px;
overflow: hidden;
margin-bottom: 10px;
}

.loading-fill {
height: 100%;
background-color: #daee01;
border-radius: 10px;
transition: width 0.5s ease;
}

.powered-by {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
  }
  
  .powered-by-text {
    font-size: 18px; /* Smaller font size for "powered by" */
    margin-top: 0px;
    color: #3f3f3f;
  }
  
  .spaces-text {
    font-size: 30px; /* Larger font size for "spaces" */
    margin-left: 7px; /* Space between "powered by" and "spaces" */
    color: #3f3f3f;
    font-family: 'Black Ops One', sans-serif;
  }
  